* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}

html {
    /* 1rem = 10px */
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
    overflow-y: overlay;
}

@media (min-width: 768px) and (max-width: 1024px) {
    html {
        font-size: 56.25%; /* 1rem = 9px */
    }
}

@media (max-width: 768px) {
    html {
        font-size: 50%; /* 1rem = 8px */
    }
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    overflow-y: overlay;
}
ol,
ul {
    padding-left: 0rem;
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    font-size: 1.4rem !important;
    font-family: 'Roboto', sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.react-tooltip {
    z-index: 9 !important;
    font-weight: normal !important;
    font-size: 12px !important;
}
